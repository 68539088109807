





















































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SensorIcon from "@/components/SensorIcon.vue";
import InformationBox from "@/components/InformationBox.vue";

@Component({
  components: {
    SensorIcon,
    InformationBox,
  },
})
export default class Analyzer extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public analyzer: any = {
    capabilities: [],
    calibration: {},
  };
  public organisation: any = null;
  public organisationOptions: any = [];

  public graphFilterOptions: any = [
    { value: "co2", label: "Analyse CO₂" },
    { value: "temperature", label: "Température" },
    { value: "humidity", label: "Humidité" },
    { value: "co", label: "Analyse CO" },
    { value: "cov", label: "Analyse COV" },
    { value: "no2", label: "Analyse NO₂" },
    { value: "pm1", label: "Analyse Particules 1" },
    { value: "pm25", label: "Analyse Particules 2.5" },
    { value: "pm10", label: "Analyse Particules 10" },
  ];

  public async created() {
    this.refresh();
  }

  @Watch("$route")
  private refresh() {
    this.loading = true;
    this.$store
      .dispatch("loadAnalyzer", {
        analyzerId: this.id,
      })
      .then((response: any) => {
        this.analyzer = response;
        if (response.organisation !== null) {
          this.organisation = response.organisation;
        } else {
          this.organisation = null;
        }
        this.loading = false;
      })
      .catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
  }

  private loadOrganisations() {
    this.$store
      .dispatch("loadOrganisations", {
        limit: 500,
        sort_by: "name",
      })
      .then((response: any) => {
        this.organisationOptions = response.organisations;
      })
      .catch((error: any) => {
        this.globalError(error);
      });
  }

  private saveOrganisation() {
    const analyzerId = this.id;
    const organisationId = this.organisation !== null ? this.organisation.id : null;
    const payload = {
      organisation_id: organisationId,
    };
    this.$store
      .dispatch("editAnalyzer", {
        analyzerId,
        payload,
      })
      .then((response: any) => {
        this.analyzer.organisation = this.organisation;
        if (this.organisation === null) {
          this.$q.notify("L'analyseur est détaché");
        } else {
          this.$q.notify({
            message: `L'analyseur est attaché à ${this.organisation.name}`,
            color: "teal",
          });
        }
      });
  }
}
